.profile-workspace {
  margin: 100px;
}

.profile-workspace .col-12 {
  padding: 0;
}

.profile-workspace .profile-fav-club {
  width: 40%;
}

.profile-workspace .profile-change-password {
  margin-top: 30px;
  width: 40%;
}

.profile-workspace .profile-email-notifications {
  margin-top: 30px;
  width: 40%;
}

.profile-workspace button {
  margin-top: 50px;
  width: 20%;
}

.profile-workspace .profile-change-password .change-password .profile-change-password-form .MuiTextField-root {
  width: 350px;
}
.profile-workspace .profile-change-password .change-password .profile-change-password-confirmation-form .MuiTextField-root{
  width: 350px;
}

.profile-workspace .profile-email-notifications .MuiFormControlLabel-root {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .profile-workspace {
    margin: 50px 15px;
  }

  .profile-workspace .profile-fav-club {
    width: 100%;
  }
  
  .goal .goal-view .goal-view-display {
    width: 100%;
  }

  .profile-workspace .profile-email-notifications {
    margin-top: 30px;
    width: 100%;
  }
  .profile-workspace button {
    margin-top: 50px;
    width: 100%;
  }
}