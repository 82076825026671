@import '../styles/goals.css';

.admin-workspace .admin-logo {
  width: 200px;
  margin: auto;
}

.admin-workspace .admin-logo .admin {
  margin: auto;
}

#admin-accordion {
  margin: 50px 300px;
}

#admin-accordion .card-body h2 {
  font-size: 30px;
  width: 100%;
  font-weight: 800;
}

#admin-accordion .card-body h3 {
  font-size: 26px;
  width: 100%;
  font-weight: 600;
}

.goalSubmission-display .goalSubmission {
  width: 750px;
}

.goalSubmission-display .goalSubmission #goalSubmission-gif {
  width: 100%;
  max-height: 405px;
}

.goalSubmission-display .display-video .goalSubmission-buttons {
    margin: auto;
    width: 75%;
}

.goalSubmission-display .goalSubmission-approved .approval {
  margin: auto;
  width: 200px;
}

.goalSubmission-display .goalSubmission-approved i {
  color: #089FDF;
  padding: 0 10px;
}

video:focus {
  outline: none;
}

.goalSubmission-buttons.row button#goalSubmission-remove {
  border: 1px solid #DE3C4B !important;
  background-color: white;
  color: white;
}

#admin-subreddit-form {
  max-width: 300px;
  margin: 60px 300px;
}

#admin-subreddit-form #admin-subreddit {
  margin: 20px 0;
}

@media only screen and (max-width: 600px) {
  .goalSubmission-display .goalSubmission {
    width: 100%;
  }

  #admin-accordion {
    margin: 0;
  }

  #admin-accordion .card-body h2 {
    font-size: 24px !important;
    width: 100%;
    font-weight: 800;
  }
  
  #admin-accordion .card-body h3 {
    font-size: 22px !important;
    width: 100%;
    font-weight: 400;
  }
}