@import '../styles/goals.css';

.club-workspace .club-section {
  width: 200px;
  margin: auto;
}

.club-workspace .club-section .club-name {
  margin: auto;
}

.club-workspace .club-section .club-name #club-selected-logo {
  margin: auto;
  width: 55px;
}

.club-workspace .club-section .club-name h3 {
  text-align: center;
}

#club-accordion {
  margin: 50px 300px;
}

#club-accordion .card-body h2 {
  font-size: 30px;
  width: 100%;
  font-weight: 800;
}

#club-accordion .card-body h3 {
  font-size: 26px;
  width: 100%;
  font-weight: 600;
}

#club-accordion .card-body .create-goal-request {
  margin: 0px 35px;
}

.goal .goal-view {
  width: 100%;
  margin: auto;
  margin-top: 50px;
}

.goal .goal-view .goal-view-display{
  width: inherit;
}



video:focus {
  outline: none;
}

.goalSubmission-buttons.row button#goalSubmission-remove {
  border: 1px solid #DE3C4B !important;
  background-color: white;
  color: white;
}

@media only screen and (max-width: 600px) {
  .goal .goal-view {
    width: 100%;
  }
  
  .goal .goal-view .goal-view-display {
    width: 100%;
  }

  #club-accordion {
    margin: 0;
  }

  #club-accordion .card-body h2 {
    font-size: 24px !important;
    width: 100%;
    font-weight: 800;
  }
  
  #club-accordion .card-body h3 {
    font-size: 22px !important;
    width: 100%;
    font-weight: 400;
  }
}