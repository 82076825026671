.reset-password-workspace .reset-password-application-name {
  margin: 0;
}

.reset-password-workspace .reset-password-application-name h2 {
  margin: auto;
  width: 250px;
  text-align: center;
  margin-top: 60px;
  font-weight: 600;
  font-family: Proxima Nova;
}

.reset-password-workspace .reset-password-raised-area {
  margin: auto;
  margin-top: 40px;
  max-width: 448px;
  width: 100%;
  height: 500px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, 
  rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.reset-password-workspace .reset-password-raised-area h3 {
  text-align: center;
  font-size: 26px;
  font-family: Montserrat;
  color: #092A55;
  margin: 80px 40px 20px;
}

.reset-password-workspace .reset-password-raised-area p {
  text-align: center;
  font-size: 18px;
  font-family: Montserrat;
  color: #092A55;
  margin: 10px 40px 30px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row {
  margin: auto;
  width: 70%;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row button {
  margin-top: 25px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row input:focus {
  outline: none;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row .reset-password-form-password {
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row .reset-password-form-password .reset-password {
  height: 100%;
  padding: 8px 16px;
  border: 1px solid #092A55;
  border-radius: 5px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row .reset-password-form-password-confirm {
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-form.row .reset-password-form-password-confirm .reset-password-confirm {
  height: 100%;
  padding: 8px 16px;
  border: 1px solid #092A55;
  border-radius: 5px;
}

.reset-password-workspace .reset-password-raised-area .reset-password-reset-password {
  cursor: default;
  padding: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {

}
