.home-workspace {
  width: 100%;
  margin: 0;
}

.home-workspace .home-results {
  width: 100%;
  margin: 0;
}

.home-workspace .home-results .home-title {
  margin: 50px auto;
}

.home-workspace .home-results .home-title h2 {
  text-align: center;
}

.home-workspace .home-results .home-result-list ul {
  list-style-type: none;
  margin: auto;
}

.home-workspace .home-results .home-result-list .card {
  width: 200px;
  margin: 15px;
  text-align: center;
  height: 200px;
  border: 1px solid black;
}

.home-workspace .home-results .home-result-list .card .card-image {
  margin: 0 auto;
  max-width: 100px;
}

.home-workspace .home-results .home-result-list .card .card-image img {
  max-width: 100px;
  align-items: center;
}

.home-workspace .home-search {
  width: 30%;
  margin: 40px auto;
}

.home-workspace .home-search input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
}

.home-results-list {
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .home-workspace .home-search {
    width: 80%;
    margin: 20px auto;
  }
}