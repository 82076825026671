.homeNav nav {
  background-color: #E18335;
}

.homeNav nav .fa-bars {
  font-size: 20px;
}

.homeNav nav .collapse.navbar-collapse .navbar-nav {
  margin-left: auto;
}