.adminNav nav {
  background-color: #E18335;
}

.adminNav nav .home-nav {
  height: 35px;
  text-align: center;
  cursor: default;
}

.adminNav nav .home-nav p {
  display: inline-block;
  font-size: 20px;
}

.adminNav nav .collapse.navbar-collapse .navbar-nav {
  margin-left: auto;
}