.login-workspace .login-application-name {
  margin: 0;
}

.login-workspace .login-application-name h2 {
  margin: auto;
  width: 250px;
  text-align: center;
  margin-top: 60px;
  font-weight: 600;
  font-family: Proxima Nova;
}

.login-workspace .login-raised-area {
  margin: auto;
  margin-top: 40px;
  max-width: 448px;
  width: 100%;
  height: 500px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, 
  rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.login-workspace .login-raised-area h3 {
  font-size: 28px;
  font-family: Montserrat;
  color: #092A55;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 40px;
}

.login-workspace .login-raised-area .login-form.row {
  margin: auto;
  width: 70%;
}

.login-workspace .login-raised-area .login-form.row input:focus {
  outline: none;
}

.login-workspace .login-raised-area .login-form.row .login-form-username {
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
}

.login-workspace .login-raised-area .login-form.row .login-form-username .login-username {
  height: 100%;
  padding: 8px 16px;
  border: 1px solid #092A55;
  border-radius: 5px;
}

.login-workspace .login-raised-area .login-form.row .login-form-password {
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
}

.login-workspace .login-raised-area .login-form.row .login-form-password .login-password {
  height: 100%;
  padding: 8px 16px;
  border: 1px solid #092A55;
  border-radius: 5px;
}

.login-workspace .login-raised-area .login-forgot-password {
  cursor: default;
  padding: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {

}
